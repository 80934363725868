import {
    get,
    post
} from '@/utils/http';

export function login(body) {
    return post('/segtrade/auth/', body)
}

export function googleLogin(token_id) {
    return post('/segtrade/auth/google', {
        "token_id": token_id
    })
}

export function facebookLogin(access_token) {
    return post('/segtrade/auth/facebook', {
        "access_token": access_token
    })
}

export function tokenAuth() {
    return get('/segtrade/auth/');
}