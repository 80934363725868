import Vue from 'vue'
import app from "@/mixins/modules/app";
import auth from "@/mixins/modules/auth";
import user from '@/mixins/modules/user';
import update from '@/mixins/modules/update';

// import css from '@/mixins/modules/css'

let mixins = [
    app,
    auth,
    user,
    update
]

for (let mixin of mixins)
    Vue.mixin(mixin);