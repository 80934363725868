import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

import css from "@/store/modules/css";
import app from "@/store/modules/app";
import auth from "@/store/modules/auth";
import chat from "@/store/modules/chat";

Vue.use(Vuex)
const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

export default new Vuex.Store({
  modules: {
    css,
    app,
    auth,
    chat
  },
  plugins: [vuexLocal.plugin]
})