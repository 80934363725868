const state = {
    token: null,
    user: null,
    hasVerifiedToken: false
}

const getters = {
    getToken(state) {
        return state.token
    },
    getUser(state) {
        return state.user
    },
    getHasVerifiedToken(state) {
        return state.hasVerifiedToken;
    }
}

// actions
const actions = {
    authenticateUser({
        commit
    }, userData) {
        const token = userData.headers.authorization
        commit('setToken', token)
        commit('setUser', userData.data.body)
    }
}

const mutations = {
    resetStore(s) {
        const initial = Object.assign({}, state);
        Object.keys(initial).forEach(key => {
            s[key] = initial[key]
        });
    },
    setToken(state, token) {
        state.token = token
    },
    setUser(state, user) {
        state.user = user
    },
    setHasVerifiedToken(state, hasVerifiedToken) {
        state.hasVerifiedToken = hasVerifiedToken
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}