import moment from "moment";

export default {
    methods: {
        getGender(g) {
            return this.$t("genders").find(
                (gender) => gender.value == g
            );
        },
        dateToLocale(date) {
            return moment(date).format('L');
        },
    }
}