import axios from 'axios';

import auth from "@/mixins/modules/auth"
import VueInstance from "@/main"

axios.interceptors.response.use((response) => {
    return response;
}, (error) => {
    if (error.response.status == 401 && !VueInstance.$route.meta.can401) {
        auth.methods.logout();
    }
    return Promise.reject(error);
});

const getHeaders = () => {
    let getToken = auth.computed.getToken();
    if (getToken) {
        return {
            'Authorization': `${getToken}`,
            'Language': process.env.VUE_APP_I18N_LOCALE
        }
    } else {
        return {
            'Language': process.env.VUE_APP_I18N_LOCALE
        }
    }
}

export function post(uri, data) {
    return axios.post(endpoint(uri), data, {
        headers: getHeaders()
    })
}

export function formData(uri, data) {
    let config = {
        headers: getHeaders()
    }
    config.headers['Content-Type'] = 'multipart/form-data'
    return axios.post(endpoint(uri), data, config)
}

export function formDataPut(uri, data) {
    let config = {
        headers: getHeaders()
    }
    config.headers['Content-Type'] = 'multipart/form-data'
    return axios.put(endpoint(uri), data, config)

}

export function put(uri, data) {
    return axios.put(endpoint(uri), data, {
        headers: getHeaders()
    })
}

export function del(uri, data) {
    return axios.delete(endpoint(uri), {
        data,
        headers: getHeaders()
    })
}

export function get(uri) {
    return axios.get(endpoint(uri), {
        headers: getHeaders()
    })
}

export function endpoint(uri) {
    return process.env.VUE_APP_API_URL + uri
}