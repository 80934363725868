import {
    tokenAuth
} from '@/api/auth'
import store from '@/store'
import router from "@/router/index"

export default {
    computed: {
        isUserLogged: function () {
            return !!store.getters['auth/getToken']
        },
        hasVerifiedToken() {
            return store.getters['auth/getHasVerifiedToken']
        },
        getUser() {
            return store.getters['auth/getUser']
        },
        getToken() {
            return store.getters['auth/getToken']
        }
    },
    methods: {
        logout() {

            let user = store.getters['auth/getUser']

            store.commit('auth/resetStore');
            store.commit('chat/resetStore');
            
            if (user) {
                if (user.user_type == 'user') {
                    router.push({
                        name: "Auth"
                    })
                } else {
                    router.push({
                        name: "PartnerAuth"
                    })
                }
            } else
                router.push({
                    name: "LandingPage"
                })
        },
        setHasVerifiedToken(hasVerifiedToken) {
            store.commit('auth/setHasVerifiedToken', hasVerifiedToken)
        },
        authWithToken() {
            tokenAuth()
                .then(response => {
                    if (response.status === 200) {
                        store.dispatch('auth/authenticateUser', response, {
                            root: true
                        })
                    }
                    this.setHasVerifiedToken(true)
                })
                .catch(error => {
                    this.logout();
                });
        }
    }


}