import auth from "@/mixins/modules/auth";

export default (to, from, next, router) => {
    if (auth.computed.getToken()) {
        if (auth.computed.getUser().user_type == "user")
            next({
                name: 'UserHome'
            })
        else if (auth.computed.getUser().user_type == "mediator")
            next({
                name: 'PartnerHome'
            })
        else if (auth.computed.getUser().user_type == "admin")
            next({
                name: 'BackOffice'
            })
    } else
        next();

}