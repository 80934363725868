const state = {
    isMobile: window.innerWidth <= 1023,
    width: window.innerWidth,
    isSidebarOpen: false,
    isChatOpen: false,
    popupType: null
}

const getters = {
    isMobile(state, getters) {
        return state.isMobile;
    },
    isSidebarOpen(state) {
        return state.isSidebarOpen;
    },
    isChatOpen(state) {
        return state.isChatOpen;
    },
    getPopupType(state) {
        return state.popupType;
    }
}

const mutations = {
    updateWidth(state, width) {
        state.isMobile = width <= 1023;
        state.width = width;
    },
    setSidebarOpen(state, bol) {
        state.isSidebarOpen = bol;
    },
    setChatOpen(state, bol) {
        state.isChatOpen = bol;
    },
    setPopupType(state, type) {
        state.popupType = type;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations
}