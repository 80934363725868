import store from "@/store";

export default {
    computed: {
        getTotalUnread() {
            return store.getters["chat/getTotalUnread"];
        },
        getRooms() {
            return store.getters["chat/getRooms"];
        },
        getSelectedRoom() {
            return store.getters["chat/getSelectedRoom"];
        }
    },
    methods: {
        setRoom(room) {
            store.commit("chat/setRoom", room);
        },
        setSelectedRoom(id) {
            store.commit("chat/setSelectedRoom", id);
        }
    }
}