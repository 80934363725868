const state = {
    navbarHeight: 0
}

const getters = {
    getNavbarHeight(state, getters) {
        return state.navbarHeight;
    }
}

const mutations = {
    setNavbarHeight(state, height) {
        state.navbarHeight = height;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations
}