<template>
	<div id="app" :class="{'has-background-gray-light': !isMobile}">
		<router-view />
	</div>
</template>

<script>
	import chat from "@/mixins/modules/chat";
	export default {
		name: "App",
		mixins: [chat],
		created() {
			addEventListener("resize", () => {
				this.updateWidth(innerWidth);
			});
		},
		sockets: {
			connect() {
				if (this.getToken) {
					this.$socket.emit("chat_ask_unread", this.getToken);
				}
			},
			chat_get_unread(rooms) {
				this.setRoom(rooms);
			},
		},
	};
</script>

<style lang="scss">
	@import "@/assets/scss/variables/colors";
	
	#app {
		color: $black;
		min-height: 100vh;
	}

	#nav {
		padding: 30px;

		a {
			font-weight: bold;
			color: #2c3e50;

			&.router-link-exact-active {
				color: #42b983;
			}
		}
	}
</style>
