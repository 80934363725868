import Vue from 'vue'
import VueRouter from 'vue-router'

import redirectHome from "@/router/guards/redirectHome";

import {
  setSelectedRoomBeforeEnter
} from "@/router/guards/chat";

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'LandingPage',
    component: () => import( /* webpackChunkName: "userLanding" */ '@/views/LandingPage.vue')
  },
  {
    path: "/auth",
    name: "Auth",
    component: () => import( /* webpackChunkName: "userLanding" */ '@/views/Auth.vue'),
    beforeEnter: redirectHome,
    meta: {
      can401: true
    }
  },
  {
    path: "/auth/recover",
    name: "RecoverPassword",
    component: () => import( /* webpackChunkName: "userLanding" */ '@/views/Auth.vue'),
    meta: {
      can401: true
    }
  },
  {
    path: "/users/activate",
    name: "Activation",
    component: () => import( /* webpackChunkName: "userLanding" */ '@/views/Auth.vue'),
    meta: {
      can401: true
    }
  },
  {
    path: "/home",
    name: "Home",
    component: () => import( /* webpackChunkName: "user" */ '@/views/Home.vue'),
    children: [{
      path: "",
      name: "UserHome",
      component: () => import( /* webpackChunkName: "user" */ '@/components/Insurances/MyInsurances'),

    }, {
      path: "simulations",
      name: "MySimulations",
      component: () => import( /* webpackChunkName: "user" */ '@/components/Simulations/MySimulations'),

    }, {
      path: "simulations/:id",
      name: "SimulationID",
      component: () => import( /* webpackChunkName: "user" */ '@/components/Simulations/SimulationID'),
    }, {
      path: "simulations/:simulationId/proposals/mediator/:mediatorId",
      name: "ProposalID",
      component: () => import( /* webpackChunkName: "user" */ '@/components/Proposals/ProposalID'),
    }, {
      path: "settings",
      name: "Settings",
      component: () => import( /* webpackChunkName: "user" */ '@/views/Settings'),
    }, {
      path: "insurances",
      name: "MyInsurances",
      component: () => import( /* webpackChunkName: "user" */ '@/components/Insurances/MyInsurances'),
    }, {
      path: "insurances/:id",
      name: "InsuranceID",
      component: () => import( /* webpackChunkName: "user" */ '@/components/Insurances/InsuranceID'),
    }, {
      path: "chat/:id",
      name: "Chat",
      component: () => import( /* webpackChunkName: "user" */ '@/views/Chat'),
      beforeEnter: setSelectedRoomBeforeEnter
    }]
  },
  {
    path: "/home/simulations/:simulationId/proposals/mediator/:mediatorId/choose",
    name: "ChooseProposal",
    component: () => import( /* webpackChunkName: "user" */ '@/components/Proposals/ChooseProposal'),
  },
  {
    path: "/simulations",
    name: "Simulation",
    component: () => import( /* webpackChunkName: "user" */ '@/views/Simulation.vue'),
    children: [{
      path: "",
      component: () => import( /* webpackChunkName: "user" */ '@/components/Forms/Choose.vue'),
      name: "ChooseSimulation"
    },{
      path: "animal",
      component: () => import( /* webpackChunkName: "user" */ '@/components/Forms/Animal.vue'),
      name: "AnimalSimulation"
    }, {
      path: "car",
      component: () => import( /* webpackChunkName: "user" */ '@/components/Forms/Car.vue'),
      name: "CarSimulation"
    },{
      path: "company_car",
      component: () => import( /* webpackChunkName: "user" */ '@/components/Forms/CompanyCar.vue'),
      name: "CompanyCarSimulation"
    },{
      path: "health",
      component: () => import( /* webpackChunkName: "user" */ '@/components/Forms/Health.vue'),
      name: "HealthSimulation"
    },{
      path: "company_health",
      component: () => import( /* webpackChunkName: "user" */ '@/components/Forms/CompanyHealth.vue'),
      name: "CompanyHealthSimulation"
    },{
      path: "house",
      component: () => import( /* webpackChunkName: "user" */ '@/components/Forms/House.vue'),
      name: "HouseSimulation"
    },{
      path: "life",
      component: () => import( /* webpackChunkName: "user" */ '@/components/Forms/Life.vue'),
      name: "LifeSimulation"
    },{
      path: "company_life",
      component: () => import( /* webpackChunkName: "user" */ '@/components/Forms/CompanyLife.vue'),
      name: "CompanyLifeSimulation"
    },{
      path: "life_credit",
      component: () => import( /* webpackChunkName: "user" */ '@/components/Forms/LifeCredit.vue'),
      name: "LifeCreditSimulation"
    },{
      path: "teeth",
      component: () => import( /* webpackChunkName: "user" */ '@/components/Forms/Teeth.vue'),
      name: "TeethSimulation"
    },{
      path: "personal_accident",
      component: () => import( /* webpackChunkName: "user" */ '@/components/Forms/PersonalAccident.vue'),
      name: "PersonalAccidentSimulation"
    },{
      path: "independent_work",
      component: () => import( /* webpackChunkName: "user" */ '@/components/Forms/IndependentWork.vue'),
      name: "IndependentWorkSimulation"
    },{
      path: "travel",
      component: () => import( /* webpackChunkName: "user" */ '@/components/Forms/Travel.vue'),
      name: "TravelSimulation"
    },{
      path: "bicycle",
      component: () => import( /* webpackChunkName: "user" */ '@/components/Forms/Bicycle.vue'),
      name: "BicycleSimulation"
    },{
      path: "motorcycle",
      component: () => import( /* webpackChunkName: "user" */ '@/components/Forms/Motorcycle.vue'),
      name: "MotorcycleSimulation"
    },{
      path: "multi_risk",
      component: () => import( /* webpackChunkName: "user" */ '@/components/Forms/MultiRisk.vue'),
      name: "MultiRiskSimulation"
    },{
      path: "weapons",
      component: () => import( /* webpackChunkName: "user" */ '@/components/Forms/Weapons.vue'),
      name: "WeaponsSimulation"
    },{
      path: "other",
      component: () => import( /* webpackChunkName: "user" */ '@/components/Forms/Other.vue'),
      name: "OtherSimulation"
    },{
      path: "responsability",
      component: () => import( /* webpackChunkName: "user" */ '@/components/Forms/Responsability.vue'),
      name: "ResponsabilitySimulation"
    },{
      path: "work_accident",
      component: () => import( /* webpackChunkName: "user" */ '@/components/Forms/WorkAccident.vue'),
      name: "WorkAccidentSimulation"
    }]
  },
  {
    path: '/partner',
    name: 'PartnerLandingPage',
    component: () => import( /* webpackChunkName: "partnerLanding" */ '@/views/Partner/LandingPage.vue')
  },
  {
    path: '/partner/auth',
    name: 'PartnerAuth',
    component: () => import( /* webpackChunkName: "partnerLanding" */ '@/views/Partner/Auth.vue'),
    beforeEnter: redirectHome,
    meta: {
      can401: true
    }
  },
  {
    path: '/partner/home',
    name: 'PartnerHome',
    component: () => import( /* webpackChunkName: "partner" */ '@/views/Partner/Home.vue'),
    children:[{
      path: "settings",
      name: "PartnerSettings",
      component: () => import( /* webpackChunkName: "partner" */ '@/views/Settings'),
    }]
  },
  {
    path: '/partner/simulations',
    name: 'PartnerSimulations',
    component: () => import( /* webpackChunkName: "partner" */ '@/views/Partner/Simulations.vue')
  },
  {
    path: '/partner/simulations/:id',
    name: 'PartnerSimulation',
    component: () => import( /* webpackChunkName: "partner" */ '@/views/Partner/Simulation.vue')
  },
  {
    path: '/partner/proposals',
    name: 'PartnerProposals',
    component: () => import( /* webpackChunkName: "partner" */ '@/views/Partner/Proposals.vue')
  }, {
    path: '/partner/proposals/:id',
    name: 'PartnerProposal',
    component: () => import( /* webpackChunkName: "partner" */ '@/views/Partner/Proposal.vue')
  },
  {
    path: '/partner/insurances',
    name: 'PartnerInsurances',
    component: () => import( /* webpackChunkName: "partner" */ '@/views/Partner/Insurances.vue')
  },
  {
    path: '/partner/insurances/:id',
    name: 'PartnerInsurance',
    component: () => import( /* webpackChunkName: "partner" */ '@/views/Partner/Insurance.vue')
  },
  {
    path: "/partner/chat/:id",
    name: "PartnerChat",
    component: () => import( /* webpackChunkName: "partner" */ '@/views/Partner/Chat'),
    beforeEnter: setSelectedRoomBeforeEnter
  },
  {
    path: '/backoffice/',
    name: 'BackOffice',
    component: () => import( /* webpackChunkName: "backoffice" */ '@/views/BackOffice/Mediators.vue')
  },
  {
    path: '/backoffice/mediators',
    name: 'BackOfficeMediators',
    component: () => import( /* webpackChunkName: "backoffice" */ '@/views/BackOffice/Mediators.vue')
  },
  {
    path: '/backoffice/users',
    name: 'BackOfficeUsers',
    component: () => import( /* webpackChunkName: "backoffice" */ '@/views/BackOffice/Users.vue')
  },
]

const router = new VueRouter({
  mode: process.env.VUE_APP_ROUTER_MODE,
  base: process.env.BASE_URL,
  routes
})

export default router