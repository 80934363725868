import browser from 'browser-detect';
let defaultImg = require("@/assets/imgs/avatar.jpg");

export default {
    computed: {
        isMobile() {
            return this.$store.getters['app/isMobile'];
        },
        isMobileDevice() {
            return process.env.CORDOVA_PLATFORM ? true : false;
        },
        isSidebarOpen() {
                return this.$store.getters['app/isSidebarOpen'] || !this.$store.getters['app/isMobile'];
        },
        isChatOpen() {
            return this.$store.getters['app/isChatOpen']
        },
        getDefaultImg() {
            return defaultImg;
        }
    },
    methods: {
        scrollToTop() {
            if (browser().name == "safari")
                setTimeout(function () {
                    scrollTo(0, 0);
                }, 100)
            else
                document.documentElement.scrollTop = 0;
        },
        scrollTo(view, hash) {
            if (this.$route.name == view)
                document.getElementById(hash).scrollIntoView(true);
            else this.$router.push({

                name: view,
                hash: `#${hash}`
            });
        },
        setDefaultImg(event, single = true) {
            if (single)
                return event.target.src = defaultImg;
            else
                return event.target.remove();
        },
        updateWidth(width) {
            this.$store.commit("app/updateWidth", width);
        },
        setSidebarOpen(bol) {
            this.$store.commit("app/setSidebarOpen", bol);
        },
        setChatOpen(bol) {
            this.$store.commit("app/setChatOpen", bol);
        },
        getTrueFalse(bol) {
            return this.$t("trueFalse").find(
                (bolean) => bolean.value.toString() == bol.toString()
            );
        },
    }
}