import Vue from "vue";

const state = {
    rooms: [],
    selected: null
};

const getters = {
    getTotalUnread(state) {
        let unread = 0;
        for (let room of state.rooms) {
            unread += room.unread;
        }
        return unread;
    },
    getRooms(state) {
        return state.rooms;
    },
    getSelectedRoom(state) {
        return state.rooms.find(r => r._id == state.selected);
    }
}

const actions = {}

const mutations = {
    resetStore(s) {
        const initial = Object.assign({}, state);
        Object.keys(initial).forEach(key => {
            s[key] = initial[key]
        });
    },
    setRoom(state, room) {

        if (Array.isArray(room)) {

            for (let r of room) {
                let found = false;
                for (let i in state.rooms) {
                    if (state.rooms[i]._id == r._id) {
                        Vue.set(state.rooms, i, r);
                        found = true;
                    }
                }
                if (!found) {
                    state.rooms.push(r);
                }
            }

        } else {
            let found = false;
            for (let i in state.rooms) {
                if (state.rooms[i]._id == room._id) {
                    Vue.set(state.rooms, i, room);
                    found = true;
                }
            }
            if (!found) {
                state.rooms.push(room);
            }
        }

        let sortedRooms = sortRooms(state.rooms);
        Vue.set(state, "rooms", sortedRooms)

    },
    setSelectedRoom(state, id) {
        state.selected = id;
    }
}


const sortRooms = (_rooms) => {
    return _rooms.sort((a, b) => (a.updatedAt > b.updatedAt) ? -1 : ((b.updatedAt > a.updatedAt) ? 1 : 0));
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}