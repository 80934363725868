import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import i18n from './i18n'
import Buefy from 'buefy'
import './assets/scss/app.scss'
import './assets/scss/spacing.scss'
import VueCookies from 'vue-cookies'

import VueSocketio from 'vue-socket.io'
Vue.use(new VueSocketio({
  debug: process.env.NODE_ENV == "production" ? false : true,
  connection: process.env.VUE_APP_API_URL,
  vuex: {
    store,
    actionPrefix: 'SOCKET_',
    mutationPrefix: 'SOCKET_'
  },
}));

Vue.use(Buefy, {
  customIconPacks: {
    st: {
      iconPrefix: "st-"
    }
  }
})

Vue.use(VueCookies)
VueCookies.config('31d')

import moment from 'moment'
moment.locale(process.env.VUE_APP_I18N_LOCALE);
Vue.prototype.moment = moment

import "./mixins/index";

Vue.config.productionTip = false

var VueInstance = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')


export default VueInstance;